html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto;
  /*font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,*/
  /*  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/
}

button {
  font-family: Roboto;
}

a {
  /*color: inherit;*/
  color: #ff822c;
  text-decoration: none;
  text-underline: #ff822c;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.mod_fc_preview_close > svg {
  box-sizing: content-box !important;
}

.tp-widget-rating {
  font-size: 13px !important;
}

@media (max-width: 600px) {
  h1 {
    font-size: 36px !important;
    line-height: 44px !important;
  }

  h2 {
    font-size: 26px!important;
    line-height: 34px!important;
  }
  h3 {
    font-size: 21px !important;
    line-height: 30px !important;
  }
  p{
    font-size: 15px !important;
    line-height: 21px !important;
  }
}

